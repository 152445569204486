import students from '../images/students.jpg'

//this is a place to store the data that we want renderd in the slides
//all three slides are of bags of love at the moment but his can be changed once we have more projects under our belt
// I wanted to add imiges of the BOL project but i dont have access to the BOL site anymore so we will have to have someone with access take screenshots
export const serviceSlidesData = [
    {
        title: "Bags of Love",
        description: "This project for the nonprofit organization Bags of Love entailed the development of a comprehensive full-stack web application, incorporating cloud storage solutions through Google Cloud Services.",
        icon: require('../images/BOL.png'),
        alt: "alternate text for image"
    },
    {
        title: "Bags of Love",
        description: "The application features capabilities to scrape emails for order forms, display them within a structured table, and facilitate their printing on labels for operational use.",
        icon: require('../images/BOL.png'),
        alt: "alternate text for image"
    },
    {
        title: "Bags of Love",
        description: "Completing the suite, this application includes an analytics platform, enabling Bags of Love to review their data comprehensively and strategize effectively for future endeavors.",
        icon: require('../images/BOL.png'),
        alt: "alternate text for image"
    }
]


export default function ServiceInfo() {
    return (
        <div className='about-intro'>
        <div className='aboutus'>About Us</div>
        <div className='service-info'>
        <img src={students} alt="Students" />
        <p className='service-info-text'>Leveraging our broad spectrum of highly skilled and dynamic young students,
         Oregon Software Consultants are fully equipped to address any technological requirements you may have. As a comprehensive full-stack development club, 
         we possess extensive experience in both front-end and back-end website/application development. Within our organization,
         we host a variety of specialized teams committed to efficiently resolving your distinct challenges, 
         ensuring that your preferences and needs are prioritized to deliver the optimum solution to our clients.</p>
        </div>
        </div>
    )
}