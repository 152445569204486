import React, { useState, useEffect } from 'react';


const Layout = ({ children }) => {
  const [showBottomSegment, setShowBottomSegment] = useState(false);
  let lastScrollY = window.scrollY; 
  const handleScroll = () => {
    const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
    const scrolledUp = window.scrollY < lastScrollY;
    if (bottom) {
      setShowBottomSegment(true);
    } else if (scrolledUp) {
      setShowBottomSegment(false); 
    }
    lastScrollY = window.scrollY; 
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  },);

  return (
    <>
      {children}
      {showBottomSegment}
    </>
  );
};

export default Layout;
