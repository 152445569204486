import { useState } from "react";
import Select from "react-select";


const Minorselector = ({ onSelectionChange }) => {
  const [selectedMinor, setSelectedMinor] = useState([]);
const minorOptions = [
    { value: "African studies", label: "African studies" },
    { value: "Arabic studies", label: "Arabic studies" },
    { value: "Art history", label: "Art history" },
    { value: "Asian studies", label: "Asian studies" },
    { value: "Bioengineering", label: "Bioengineering" },
    { value: "Biology", label: "Biology" },
    { value: "Black studies", label: "Black studies" },
    { value: "Chinese", label: "Chinese" },
    { value: "Cinema studies", label: "Cinema studies" },
    { value: "Classical civilization", label: "Classical civilization" },
    { value: "Classics", label: "Classics" },
    { value: "Climate studies", label: "Climate studies" },
    { value: "Comics and cartoon studies", label: "Comics and cartoon studies" },
    { value: "Commerce and society", label: "Commerce and society" },
    { value: "Comparative literature", label: "Comparative literature" },
    { value: "Computer and information technology", label: "Computer and information technology" },
    { value: "Creative writing", label: "Creative writing" },
    { value: "Criminology", label: "Criminology" },
    { value: "Digital humanities", label: "Digital humanities" },
    { value: "Disability studies", label: "Disability studies" },
    { value: "East Asian studies", label: "East Asian studies" },
    { value: "Economics", label: "Economics" },
    { value: "Environmental humanities", label: "Environmental humanities" },
    { value: "Environmental studies", label: "Environmental studies" },
    { value: "Ethics", label: "Ethics" },
    { value: "European studies", label: "European studies" },
    { value: "Folklore and public culture", label: "Folklore and public culture" },
    { value: "Food studies", label: "Food studies" },
    { value: "French", label: "French" },
    { value: "Geography", label: "Geography" },
    { value: "German", label: "German" },
    { value: "Global health", label: "Global health" },
    { value: "Global service", label: "Global service" },
    { value: "Global studies", label: "Global studies" },
    { value: "Greek", label: "Greek" },
    { value: "Historic preservation", label: "Historic preservation" },
    { value: "History", label: "History" },
    { value: "Humanities", label: "Humanities" },
    { value: "Indigenous, race, and ethnic studies", label: "Indigenous, race, and ethnic studies" },
    { value: "Interdisciplinary cognitive sciences", label: "Interdisciplinary cognitive sciences" },
    { value: "Italian", label: "Italian" },
    { value: "Japanese", label: "Japanese" },
    { value: "Judaic studies", label: "Judaic studies" },
    { value: "Korean", label: "Korean" },
    { value: "Landscape architecture", label: "Landscape architecture" },
    { value: "Latin", label: "Latin" },
    { value: "Latin American studies", label: "Latin American studies" },
    { value: "Latinx studies", label: "Latinx studies" },
    { value: "Legal studies", label: "Legal studies" },
    { value: "Linguistics", label: "Linguistics" },
    { value: "Marine biology", label: "Marine biology" },
    { value: "Mathematics and computer science", label: "Mathematics and computer science" },
    { value: "Media studies", label: "Media studies" },
    { value: "Medieval studies", label: "Medieval studies" },
    { value: "Middle East-North Africa studies", label: "Middle East-North Africa studies" },
    { value: "Multimedia", label: "Multimedia" },
    { value: "Neuroscience", label: "Neuroscience" },
    { value: "Nonprofit administration", label: "Nonprofit administration" },
    { value: "Philosophy", label: "Philosophy" },
    { value: "Physics", label: "Physics" },
    { value: "Political science", label: "Political science" },
    { value: "Popular music", label: "Popular music" },
    { value: "Psychology", label: "Psychology" },
    { value: "Public relations", label: "Public relations" },
    { value: "Queer studies", label: "Queer studies" },
    { value: "Religious studies", label: "Religious studies" },
    { value: "Romance languages", label: "Romance languages" },
    { value: "Russian, East European, and Eurasian studies", label: "Russian, East European, and Eurasian studies" },
    { value: "Scandinavian", label: "Scandinavian" },
    { value: "Science communication", label: "Science communication" },
    { value: "Sociology", label: "Sociology" },
    { value: "South Asian studies", label: "South Asian studies" },
    { value: "Southeast Asian studies", label: "Southeast Asian studies" },
    { value: "Spanish", label: "Spanish" },
    { value: "Spatial data science and technology", label: "Spatial data science and technology" },
    { value: "Special education", label: "Special education" },
    { value: "Sports business", label: "Sports business" },
    { value: "Sustainable business", label: "Sustainable business" },
    { value: "Theater arts", label: "Theater arts" },
    { value: "Women’s, Gender, and Sexuality Studies", label: "Women’s, Gender, and Sexuality Studies" },
    { value: "Writing, public speaking, and critical reasoning", label: "Writing, public speaking, and critical reasoning" },
  ];
  

  const handleChange = (selectedOptions) => {
    setSelectedMinor(selectedOptions);
    onSelectionChange(selectedOptions.map(option => option.value));

  };

  return (
    <Select isMulti
      options={minorOptions}
      value={selectedMinor}
      onChange={handleChange}
      id="Minor"
      name="message"
    />
  );
};

export default Minorselector;