import { Box,Input,FormLabel,Button,Stack } from "@chakra-ui/react"
import Majorselector from "./Majorselector";
import Minorselector from "./Minorselector";
import Contactradio from "./Contactradio";

export default function Studentform({studentForm, submitForm, setMajorSelection, setMinorSelection, setUoemail, 
  setWhy,
  setTime,
  setStudy,
  setStudentname,}){
  

    const handleMinorSelectionChange = (selectedValues) => {
        setMinorSelection(selectedValues);
      };
    
      const handleMajorSelectionChange = (selectedValues) => {
        setMajorSelection(selectedValues);
      };

    return(
          <Box className="contact">
            <Box className="contact-inquiry">
              <form enctype="multipart/form-data" method="post" ref={studentForm}>
                <Stack spacing={10} className="inputbox">
                  <FormLabel htmlFor="name"> Name (first last)*</FormLabel>
                  <Input
                    type="text"
                    placeholder="John Doe"
                    id="studentname"
                    className="input"
                    name="message"
                    onChange={(e) => setStudentname(e.target.value)}
                  />
                  <FormLabel htmlFor="email">UO Email *</FormLabel>
                  <Input
                    type="email"
                    id="uoemail"
                    placeholder="johndoe@gmail.com"
                    className="input"
                    name="message"
                    onChange={(e) => setUoemail(e.target.value)}
                  />
                  <FormLabel id='GradRadio' htmlFor="radio">Graduation Year *</FormLabel>
                  <Box className="GradRadio">
                    <input
                      type="radio"
                      id="2024"
                      name="GradRadio"
                      value="2024"
                    />
                      <label for="2024">2024</label>
                    <br />
                    <input
                      type="radio"
                      id="2025"
                      name="GradRadio"
                      value="2025"
                    />
                      <label for="2025">2025</label>
                    <br />
                    <input
                      type="radio"
                      id="2026"
                      name="GradRadio"
                      value="2026"
                    />
                      <label for="2026">2026</label>
                    <br />
                    <input
                      type="radio"
                      id="2027"
                      name="GradRadio"
                      value="2027"
                    />
                      <label for="2027">2027</label>
                    <br />
                    <input
                      type="radio"
                      id="2028"
                      name="GradRadio"
                      value="2028"
                    />
                      <label for="2028">2028</label>
                    <br />
                    <input
                      type="radio"
                      id="2029"
                      name="GradRadio"
                      value="2029"
                    />
                      <label for="2029">2029</label>
                    <br />
                  </Box>
                  <FormLabel id="Major" htmlFor="Major">Select your Major*</FormLabel>
                  <Majorselector
                    onSelectionChange={handleMajorSelectionChange}
                  />
                  <FormLabel id="Minor" htmlFor="Minor">Select your Minor *</FormLabel>
                  <Minorselector
                    onSelectionChange={handleMinorSelectionChange}
                  />
                  <FormLabel htmlFor="Why">
                    Why are you applying for this role? *
                  </FormLabel>
                  <textarea
                    type="textarea"
                    placeholder="Why"
                    id="Why"
                    name="message"
                    onChange={(e) => setWhy(e.target.value)}
                  />
                  <FormLabel htmlFor="Time-Commitments">
                    This group requires constant participation, what other time
                    commitments do you have?*
                  </FormLabel>
                  <textarea
                    type="textarea"
                    placeholder="Time Commitments"
                    id="Time-Commitments"
                    name="message"
                    onChange={(e) => setTime(e.target.value)}
                  />
                  <FormLabel htmlFor="studyabroad">
                    Do you have any plans to study abroad. If so, when?*
                  </FormLabel>
                  <textarea
                    type="textarea"
                    placeholder="Study Abroad"
                    id="studyabroad"
                    name="message"
                    onChange={(e) => setStudy(e.target.value)}
                  />
                  <FormLabel htmlFor="Anything">
                    Is there anything else you'd like to share that we haven't
                    covered?
                  </FormLabel>
                  <textarea
                    type="textarea"
                    placeholder="Anything Else?"
                    id="Anything"
                    name="message"
                  />
                  <FormLabel id="studenthear" htmlFor="radio">
                    Where did you hear about us? *
                  </FormLabel>
                  <Contactradio />
                  <FormLabel htmlFor="questions">Questions?</FormLabel>
                  <textarea
                    type="textarea"
                    placeholder="Questions?"
                    id="questions"
                    name="message"
                  />
                </Stack>
                <Button onClick={(e) => submitForm(e, studentForm)}>
                  Submit
                </Button>
              </form>
            </Box>
          </Box>
    )
}