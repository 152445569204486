import Navbar from "../components/Navbar"
import BottomSegment from "../components/Bottomsegment"
import { Box } from "@chakra-ui/react";


export default function Thankyoupage(){
    return(
        <>
        <Navbar />
        <Box className="thank-you">Thank you for your inquiry we will be in touch soon</Box>
        <BottomSegment/>
        </>
    )
}