import { Box } from "@chakra-ui/react"
import Navbar from "../components/Navbar.jsx"
import Inputbox from "../components/Input.jsx"
import BottomSegment from "../components/Bottomsegment.jsx";


// This is the Contact page which is built with 2 components 

export default function Contact() {
    return(
        <Box>
        <Navbar />
        <Inputbox />
        <BottomSegment />
        </Box>
    );
}