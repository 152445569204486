import {Box} from "@chakra-ui/react";

// Text on mission statement and short blurb to be loaded into home page

export default function Homepagetext(){
    return(
        <Box className = "about-us" >
            <Box className="title weare">Who We Are</Box>
            <Box className="text">
            The Oregon Software Consulting (OSC) group is a student-led organization based at the University of Oregon. 
            With a commitment to making a meaningful difference, OSC brings together bright, motivated students who share a passion for technology and community service. 
            Our team consists of forward-thinking students who are passionate about combining their technological skills with a desire to contribute meaningfully to the Eugene community.
            </Box>
        </Box>
    )
}
