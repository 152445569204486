
import { Box } from "@chakra-ui/react"
import Navbar from "../components/Navbar.jsx"
import About from "../components/Aboutus.jsx";
import AboutInfo from "../data/AboutData.jsx";
import BottomSegment from "../components/Bottomsegment.jsx";
import StudentGraph from "../components/Studentsgraph.jsx";


// This is the Contact page which is built with 2 components 

export default function ServicesPage(){
    return(
        <Box>
            <Navbar />
            <AboutInfo />
            <StudentGraph />
            <About /> 
            <BottomSegment />    
        </Box>
    );
}