import {Link as ReactRouterlink} from "react-router-dom";
import { Box,Link,Button } from "@chakra-ui/react";
import { PAGE_ROUTES } from "../data/routes.js";

// call to action buttons to be loaded into Homepage

export default function Calltoaction(){
    return (
        <Box className="calltoaction">
        <Link  as = {ReactRouterlink} to = {PAGE_ROUTES.Handshake} >
            <Button className = "join-button">Join The Team</Button>
        </Link>
        <Link as = {ReactRouterlink} to = {PAGE_ROUTES.Contact} >
            <Button className = "consultation-button"> Free Consultation </Button>
        </Link>
        </Box>
    )
}