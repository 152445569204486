import '../styles/App.css';
import { Route, Routes } from 'react-router-dom';
import Home from "../pages/Homepage"
import Contact from "../pages/Contactpage"
import Aboutus from '../pages/Aboutuspage';
import Thankyoupage from '../pages/Thankyoupage';

// App loads specific pages based on the route of the URL using react router
// https://reactrouter.com/en/main

export default function App() {
  return (
      <Routes>
        <Route path="/" element={<Home />} />  
        <Route path="/Contact" element = {<Contact />} />   
        <Route path="/About" element = {<Aboutus />} /> 
        <Route path="/ThankYou" element = {<Thankyoupage />} /> 
      </Routes>
  );
}