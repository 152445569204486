import { Box} from "@chakra-ui/react";

//Introduction to club to be displayed on home page

export default function Introduction (){
    return(
        <Box>
        <Box className="intro"> Oregon Software Consulting</Box>
        <Box className = "description"> Consulting. Software Development. </Box>
        <Box className= "description" > Student Led. </Box>
        </Box>
    )
}